import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dai-log`}</h1>
    <h2>{`Welcome to the conversation about how to create cohesive community`}</h2>
    <p>{`This website is built so that anyone can `}<strong parentName="p">{`learn`}</strong>{` from it, `}<strong parentName="p">{`use`}</strong>{` it, `}<strong parentName="p">{`contribute content`}</strong>{` to it, and `}<strong parentName="p">{`extend`}</strong>{` it easily.
We want the people who write captivating ideas, and the people who code awesome tools, and the people who design our community brand
to be in a constant dialogue where ideas circulate smoothly and we all benefit from each other's unique skills.`}</p>
    <p>{`This section has two parts to it:`}</p>
    <ol>
      <li parentName="ol">{`dialogic guides to contributing the best content possible, leveraging the unique features of `}<inlineCode parentName="li">{`mdx`}</inlineCode><br parentName="li"></br>
        {`and the bouquet of powerful React components we have built for you. We'll take you from tentative comments in Google Docs to
coding your own React components in a few short weeks...`}</li>
      <li parentName="ol">{`an engineering log - or `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Colophon_(publishing)"
        }}>{`colophon`}</a>{` - explaining how we built the site so that you can understand its inner workings, help us
extend it and make it even more awesome, or use it as a boilerplate for your own project.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      